* {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    margin: 0;
}

body {
    background-color: white;
    font-size: 14px;
}

@media only screen {
    @media (min-width: 400px) {
        body {
            font-size: 15px;
        }
    }
    @media (min-width: 992px) {
        body {
            font-size: 1.16vw;
        }
    }
}
